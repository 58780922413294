<!-- 5 如何報名 @lynu369 -->

<template>
  <div
    class="hidden md:block relative h-[2150px] lg:h-[1900px] xl:h-[1600px] w-[full] overflow-hidden z-20 box-border"
  >
    <div
      class="font-Mantou text-[#43473E] text-[36px] text-[400] absolute top-[2%] left-[50%] translate-x-[-50%]"
    >
      如何報名
    </div>
    <object
      data="/home/icon-ve77.svg"
      type="image/svg+xml"
      class="absolute top-[0%] left-[3%]"
    ></object>
    <object
      data="/home/icon-ve78.svg"
      type="image/svg+xml"
      class="absolute top-[11%] left-[0%]"
    ></object>
    <object
      data="/home/icon-ve79.svg"
      type="image/svg+xml"
      class="absolute top-[53%] left-[70.5%]"
    ></object>
    <object
      data="/home/icon-ve80.svg"
      type="image/svg+xml"
      class="absolute top-[70%] left-[50%]"
    ></object>
    <!-- <object
      data="/home/icon-gr113.svg"
      type="image/svg+xml"
      class="absolute w-[80%] top-[8%] left-[50%] translate-x-[-50%]"
    ></object>
    <div class="absolute w-[100%] top-[14%] flex left-[50%] translate-x-[-50%]">
      <div
        class="flex flex-1 flex-col items-center gap-1 font-Inter text-[20px] font-normal text-center"
      >
        <div>開始報名</div>
        <div>9/2（一）~ 9/23（一）8:00 前</div>
      </div>
      <div
        class="flex flex-1 flex-col items-center gap-1 font-Inter text-[20px] font-normal text-center"
      >
        <div>正取隊伍與所屬企業公布</div>
        <div>9/24（二）8:00 前</div>
      </div>
      <div
        class="flex flex-1 flex-col items-center gap-1 font-Inter text-[20px] font-normal text-center"
      >
        <div>繳費期間</div>
        <div>9/24（二）~ 9/27（五）</div>
      </div>
      <div
        class="flex flex-1 flex-col items-center gap-1 font-Inter text-[20px] font-normal text-center"
      >
        <div>備取遞補期間</div>
        <div>9/28（六）～ 10/1（二）</div>
      </div>
    </div> -->
    <div class="absolute top-[8%] left-[50%] translate-x-[-50%] w-[80%]">
      <div class="flex flex-col items-center">
        <div class="grid grid-cols-4 gap-y-4">
          <div class="col-span-4 flex flex-col items-center">
            <object
              data="/home/icon-gr113.svg"
              type="image/svg+xml"
              class="w-[80%]"
            ></object>
          </div>
          <div
            class="flex flex-col items-center font-Inter text-[20px] font-normal text-center"
          >
            <div
              class="flex flex-1 flex-col items-center gap-1 font-Inter text-[20px] font-normal text-center"
            >
              <div>開始報名</div>
              <div>9/10（二）~ 9/24（二）08:00 前</div>
            </div>
          </div>
          <div
            class="flex flex-1 flex-col items-center gap-1 font-Inter text-[20px] font-normal text-center"
          >
            <div>正取隊伍與所屬企業公布</div>
            <div>9/25（三）00:00 前</div>
          </div>
          <div
            class="flex flex-1 flex-col items-center gap-1 font-Inter text-[20px] font-normal text-center"
          >
            <div>繳費期間</div>
            <div>9/25（三）~ 9/27（五）</div>
          </div>
          <div
            class="flex flex-1 flex-col items-center gap-1 font-Inter text-[20px] font-normal text-center"
          >
            <div>備取遞補期間</div>
            <div>9/28（六）～ 9/30（一）</div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="grid grid-cols-2 gap-y-6 absolute top-[24%] left-[50%] w-[70%] translate-x-[-50%]"
    >
      <div
        class="col-span-2 border-[#D9D9D9] border-[3px] rounded-[40px] px-14 py-10 bg-white"
      >
        <div class="flex flex-col gap-4">
          <div class="font-Mantou text-[#43473E] text-[24px] text-[400]">
            報名費用
          </div>
          <div
            class="flex flex-col font-Inter text-[16px] text-[400] leading-tight"
          >
            <div>
              $900 / 人（另收取保證金 $200）<br />
              <ul class="list-disc ml-5">
                <li>跨域組隊：隊伍成員每人減免＄100</li>
                <ul class="list-disc ml-5">
                  <li>報名隊伍內有三個（含）以上不同科系</li>
                  <li>
                    限創客交流組：該組內含高中職、大專院校生或碩博生、社會人士兩種（含）以上身份別之參賽者
                  </li>
                </ul>
                <li>
                  收費含餐食與娛樂活動、場地清潔、保險、文宣品、紀念 T 恤等
                </li>
              </ul>
            </div>
          </div>
          <div class="flex flex-col gap-4">
            <div class="font-Mantou text-[#43473E] text-[24px] text-[400]">
              保證金制度
            </div>
            <div
              class="flex flex-col font-Inter text-[16px] text-[400] leading-tight"
            >
              <div>
                若有以下情事，主辦單位將酌情不予退還個人之保證金：<br />
                <ul class="list-disc ml-5">
                  <li>
                    隊內無人參加錄取企業或新竹市府所辦理之賽前工作坊（若企業有開放線上參與，則隊內須至少一人參與線上或實體之賽前工作坊），未有特殊理由之隊伍參賽者
                  </li>
                  <li>
                    未確實完成本活動之開、閉幕式或無故遲到超過 20
                    分鐘者（以簽到、簽退手續為準）
                  </li>
                  <li>在活動場地違規飲食者，經工作人員勸導超過 3 次</li>
                  <li>
                    於活動期間，破壞任何活動場地內的設備，且必要時需負理賠責任
                  </li>
                  <li>未於組別 Demo 規定時間報到、登記、上傳簡報檔者</li>
                  <li>
                    未遵守現場工作人員指示使用娛樂交流區相關設施者，且必要時需自負賠償責任
                  </li>
                  <li>擅自進入本活動場域內非開放之管制空間者</li>
                </ul>
              </div>
            </div>
          </div>

          <div class="flex flex-col gap-4">
            <div class="font-Mantou text-[#43473E] text-[24px] text-[400]">
              報名資格
            </div>
            <div
              class="flex flex-col font-Inter text-[16px] text-[400] leading-tight"
            >
              <div>
                黑客組：全台大專院校生以及碩博士生，每隊至少需有一人員具備使用企業提供之資源的能力<br />
                創客交流組：高中職、大專院校及碩博士、社會人士皆可報名，參賽者需於比賽
                Demo
                時讓評審實際操作作品。（參考工具：figma、Miro、Webflow、Sketch）
              </div>
            </div>
          </div>
          <div class="flex flex-col gap-4">
            <div class="font-Mantou text-[#43473E] text-[24px] text-[400]">
              報名方式
            </div>
            <div
              class="flex flex-col font-Inter text-[16px] text-[400] leading-tight"
            >
              <div>
                採自行報名組隊，梅竹黑客松提供
                <a
                  href="https://www.facebook.com/groups/mchackathon.club.2.0/"
                  class="underline"
                  target="_blank"
                >
                  臉書媒合社群</a
                > 供大家找隊友<br />
                黑客組：每隊 3~5 人<br />
                創客交流組：每隊 3~4 人<br />
                <ul class="list-disc ml-5">
                  <li>
                    分為兩種報名渠道：黑客創客混合報名、創客交流組獨立報名
                  </li>
                  <ul class="list-disc ml-5">
                    <li>黑客組報名採志願排序制，須將創客交流組加入排序中</li>
                  </ul>
                  <li>若黑客組之單一企業超額，將採亂數分發</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="flex justify-between items-start gap-4">
            <div class="flex flex-col gap-4 w-[70%]">
              <div class="font-Mantou text-[#43473E] text-[24px] text-[400]">
                其他注意事項
              </div>
              <div
                class="flex flex-col font-Inter text-[16px] text-[400] leading-tight"
              >
                <ul class="list-disc ml-5">
                  <li>
                    報名之隊伍需在報名手續最末同意「<span class="text-red-500 font-bold">智慧財產權聲明暨肖像授權</span>」相關條款。
                  </li>
                  <li>
                    繳費成功後，請以組別為單位回傳證明給主辦單位；同時，一併回傳隊伍中「每一位」參賽者簽署之「<span class="text-red-500 font-bold">個人資料蒐集聲明同意書</span>」。
                  </li>
                  <li>
                    因應過夜需求，請於活動首日（10/19）配合本團隊簽署「<span class="text-red-500 font-bold">過夜責任歸屬同意書</span>」；此外，主辦方保障在工三區域過夜之參賽者安全，但若選擇在工三以外地點過夜，請自行負責相關安全事宜。
                  </li>
                  <li>
                    若報名人數超過活動最大人數上限，將依照報名先後順序錄取。
                  </li>
                  <li>
                    為保護梅竹黑客松協辦企業之權益，參賽者必須配合參賽之企業組別所提出比賽過程之保密協定簽署，若無法配合企業之保密協定，將取消參賽資格。
                  </li>
                  <li>
                    得獎同學需填寫 <a
                      href="https://docs.google.com/forms/d/e/1FAIpQLSfpq4AGg_hFSsRgi4WSBapoL7AFeE7uC0j1yZqP2i9VL6SmaA/viewform"
                      class="underline"
                      target="_blank"
                    >作品上傳表單</a>，將作品上傳至 <a
                      href="https://tenyear.meichuhackathon.org/"
                      class="underline"
                      target="_blank"
                    >
                      梅竹黑客松成果存放平台</a>。
                  </li>
                </ul>
              </div>
            </div>
            <!-- <div
              class="w-[22%] hover:bg-[#FFFFFF] hover:text-[#D8E022] active:border-[#A1A8AD] active:text-[#A1A8AD] border-[#D8E022] border-[3px] rounded-[25px] px-9 py-8 mt-12 bg-[#D8E022] flex flex-col items-center justify-center text-white font-Mantou text-[40px] text-[400] leading-normal transition-all duration-300 ease-out cursor-pointer whitespace-nowrap"
            >
              <a href="/form"
                ><div>點我</div>
                <div>報名</div></a
              >
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="block md:hidden relative h-[1440px] w-[full] overflow-hidden z-20 box-border"
  >
    <div
      class="font-Mantou text-[#43473E] text-[20px] text-[400] absolute top-[2%] left-[50%] translate-x-[-50%]"
    >
      如何報名
    </div>
    <object
      data="/home/icon-ve91.svg"
      type="image/svg+xml"
      class="absolute top-[10%] left-[60%]"
    ></object>
    <object
      data="/home/icon-ve92.svg"
      type="image/svg+xml"
      class="absolute top-[52%] left-[58%]"
    ></object>
    <object
      data="/home/icon-ve93.svg"
      type="image/svg+xml"
      class="absolute top-[60%] left-[87%]"
    ></object>

    <div class="absolute top-[5.5%] left-[30%] flex items-start gap-4">
      <div class="w-[11%] h-[11%] relative">
        <object
          data="/home/icon-el25.svg"
          type="image/svg+xml"
          class="w-full h-full"
        ></object>
        <object
          data="/home/icon-ve76-2.svg"
          type="image/svg+xml"
          class="absolute top-[8%] left-[50%] h-[180px] transform -translate-x-1/2"
        ></object>
        <div
          class="absolute inset-0 flex items-center justify-center font-Mantou text-[14px] text-[#FFFFFF] text-[400] font-normal text-center"
        >
          1
        </div>
      </div>

      <div class="flex flex-col gap-1 font-Inter text-[10px] font-normal">
        <div>開始報名</div>
        <div>9/10（二）~ 9/24（二） 08:00 前</div>
      </div>
    </div>
    <div class="absolute top-[9.5%] left-[30%] flex items-start gap-4">
      <div class="w-[13%] h-[13%] relative">
        <object
          data="/home/icon-el25.svg"
          type="image/svg+xml"
          class="w-full h-full"
        ></object>
        <div
          class="absolute inset-0 flex items-center justify-center font-Mantou text-[14px] text-[#FFFFFF] text-[400] font-normal text-center"
        >
          2
        </div>
      </div>

      <div class="flex flex-col gap-1 font-Inter text-[10px] font-normal">
        <div>正取隊伍與所屬企業公布</div>
        <div>9/25（三） 00:00 前</div>
      </div>
    </div>

    <div class="absolute top-[13.5%] left-[30%] flex items-start gap-4">
      <div class="w-[13%] h-[13%] relative">
        <object
          data="/home/icon-el25.svg"
          type="image/svg+xml"
          class="w-full h-full"
        ></object>
        <div
          class="absolute inset-0 flex items-center justify-center font-Mantou text-[14px] text-[#FFFFFF] text-[400] font-normal text-center"
        >
          3
        </div>
      </div>

      <div class="flex flex-col gap-1 font-Inter text-[10px] font-normal">
        <div>繳費期間</div>
        <div>9/25（三）~ 9/27（五）</div>
      </div>
    </div>

    <div class="absolute top-[17.5%] left-[30%] flex items-start gap-4">
      <div class="w-[13%] h-[13%] relative">
        <object
          data="/home/icon-el25.svg"
          type="image/svg+xml"
          class="w-full h-full"
        ></object>
        <div
          class="absolute inset-0 flex items-center justify-center font-Mantou text-[14px] text-[#FFFFFF] text-[400] font-normal text-center"
        >
          4
        </div>
      </div>

      <div class="flex flex-col gap-1 font-Inter text-[10px] font-normal">
        <div>備取遞補期間</div>
        <div>9/28（六）～ 9/30（一）</div>
      </div>
    </div>

    <object
      data="/home/icon-co47.svg"
      type="image/svg+xml"
      class="signup-co47 absolute top-[75%] left-[50%] transform -translate-x-1/2"
    ></object>
    <object
      data="/home/icon-ve94.svg"
      type="image/svg+xml"
      class="absolute top-[72%] left-[0%]"
    ></object>
    <object
      data="/home/icon-co54.svg"
      type="image/svg+xml"
      class="signup-co54 absolute top-[92%] left-[64%]"
    ></object>
    <div
      class="grid grid-cols-2 gap-y-4 absolute top-[22%] left-[50%] w-[86%] translate-x-[-50%]"
    >
      <div
        class="col-span-2 border-[#D9D9D9] border-[2px] rounded-[20px] px-5 py-5 pb-16 bg-white"
      >
        <div class="flex flex-col gap-2">
          <div class="font-Mantou text-[#43473E] text-[16px] text-[400]">
            報名費用
          </div>
          <div
            class="flex flex-col font-Inter text-[10px] text-[400] leading-tight"
          >
            <div>
              $900 / 人（另收取保證金 $200）<br />
              <ul class="list-disc ml-5">
                <li>跨域組隊：隊伍成員每人減免＄100</li>
                <ul class="list-disc ml-5">
                  <li>報名隊伍內有三個（含）以上不同科系</li>
                  <li>
                    限創客交流組：該組內含高中職、大專院校生或碩博生、社會人士兩種（含）以上身份別之參賽者
                  </li>
                </ul>
                <li>
                  收費含餐食與娛樂活動、場地清潔、保險、文宣品、紀念 T 恤等
                </li>
              </ul>
            </div>
          </div>
          <div class="font-Mantou text-[#43473E] text-[16px] text-[400]">
            保證金制度
          </div>
          <div
            class="flex flex-col font-Inter text-[10px] text-[400] leading-tight"
          >
            <div>
              若有以下情事，主辦單位將酌情不予退還個人之保證金：<br />
              <ul class="list-disc ml-5">
                <li>
                  隊內無人參加錄取企業或新竹市府所辦理之賽前工作坊（若企業有開放線上參與，則隊內須至少一人參與線上或實體之賽前工作坊），未有特殊理由之隊伍參賽者
                </li>
                <li>
                  未確實完成本活動之開、閉幕式或無故遲到超過 20
                  分鐘者（以簽到、簽退手續為準）
                </li>
                <li>在活動場地違規飲食者，經工作人員勸導超過 3 次</li>
                <li>
                  於活動期間，破壞任何活動場地內的設備，且必要時需負理賠責任
                </li>
                <li>未於組別 Demo 規定時間報到、登記、上傳簡報檔者</li>
                <li>
                  未遵守現場工作人員指示使用娛樂交流區相關設施者，且必要時需自負賠償責任
                </li>
                <li>擅自進入本活動場域內非開放之管制空間者</li>
              </ul>
            </div>
          </div>
          <div class="flex flex-col gap-2">
            <div class="font-Mantou text-[#43473E] text-[16px] text-[400]">
              報名資格
            </div>
            <div
              class="flex flex-col font-Inter text-[10px] text-[400] leading-tight"
            >
              <div>
                黑客組：全台大專院校生以及碩博士生，每隊至少需有一人員具備使用企業提供之資源的能力<br />
                創客交流組：高中職、大專院校及碩博士、社會人士皆可報名，參賽者需於比賽
                Demo
                時讓評審實際操作作品。（參考工具：figma、Miro、Webflow、Sketch）
              </div>
            </div>
          </div>
          <div class="flex flex-col gap-2">
            <div class="font-Mantou text-[#43473E] text-[16px] text-[400]">
              報名方式
            </div>
            <div
              class="flex flex-col font-Inter text-[10px] text-[400] leading-tight"
            >
              <div>
                採自行報名組隊，梅竹黑客松提供
                <a
                  href="https://www.facebook.com/groups/mchackathon.club.2.0/"
                  class="underline"
                  target="_blank"
                >
                  臉書媒合社群</a
                > 供大家找隊友<br />
                黑客組：每隊 3~5 人<br />
                創客交流組：每隊 3~4 人<br />
                <ul class="list-disc ml-5">
                  <li>
                    分為兩種報名渠道：黑客創客混合報名、創客交流組獨立報名
                  </li>
                  <ul class="list-disc ml-5">
                    <li>黑客組報名採志願排序制，須將創客交流組加入排序中</li>
                  </ul>
                  <li>若黑客組之單一企業超額，將採亂數分發</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="flex flex-col gap-2">
            <div class="font-Mantou text-[#43473E] text-[16px] text-[400]">
              其他注意事項
            </div>
            <div
              class="flex flex-col font-Inter text-[10px] text-[400] leading-tight"
            >
            <ul class="list-disc ml-5">
                  <li>
                    報名之隊伍需在報名手續最末同意「<span class="text-red-500 font-bold">智慧財產權聲明暨肖像授權</span>」相關條款。
                  </li>
                  <li>
                    繳費成功後，請以組別為單位回傳證明給主辦單位；同時，一併回傳隊伍中「每一位」參賽者簽署之「<span class="text-red-500 font-bold">個人資料蒐集聲明同意書</span>」。
                  </li>
                  <li>
                    因應過夜需求，請於活動首日（10/19）配合本團隊簽署「<span class="text-red-500 font-bold">過夜責任歸屬同意書</span>」；此外，主辦方保障在工三區域過夜之參賽者安全，但若選擇在工三以外地點過夜，請自行負責相關安全事宜。
                  </li>
                  <li>
                    若報名人數超過活動最大人數上限，將依照報名先後順序錄取。
                  </li>
                  <li>
                    為保護梅竹黑客松協辦企業之權益，參賽者必須配合參賽之企業組別所提出比賽過程之保密協定簽署，若無法配合企業之保密協定，將取消參賽資格。
                  </li>
                  <li>
                    得獎同學需填寫 <a
                      href="https://docs.google.com/forms/d/e/1FAIpQLSfpq4AGg_hFSsRgi4WSBapoL7AFeE7uC0j1yZqP2i9VL6SmaA/viewform"
                      class="underline"
                      target="_blank"
                    >作品上傳表單</a>，將作品上傳至 <a
                      href="https://tenyear.meichuhackathon.org/"
                      class="underline"
                      target="_blank"
                    >
                      梅竹黑客松成果存放平台</a>。
                  </li>
                </ul>
            </div>
          </div>
          <!-- <router-link to="/form"
            ><div
              class="absolute top-[93%] left-[50%] w-[22%] col-span-2 hover:bg-[#FFFFFF] hover:text-[#D8E022] active:border-[#A1A8AD] active:text-[#A1A8AD] border-[#D8E022] border-[2px] rounded-[10px] px-2 py-1 bg-[#D8E022] flex flex-col items-center justify-center text-white font-Inter text-[10px] text-[700] leading-normal transition-all duration-300 ease-out cursor-pointer transform -translate-x-1/2"
            >
              <div>我要報名</div>
            </div></router-link
          > -->
        </div>
      </div>
    </div>
  </div>
</template>

<script setup></script>

<style scoped>
.signup-co47 {
  animation: animateCo47 3s ease-out infinite;
}

.signup-co54 {
  animation: animateCo54 5s ease-out infinite;
}

@keyframes animateCo47 {
  0% {
    transform: translateX(-50%) translateY(0);
  }
  25% {
    transform: translateX(-50%) translateY(-32px);
  }
  50% {
    transform: translateX(-50%) translateY(-32px);
  }
  75% {
    transform: translateX(-50%) translateY(0);
  }
  100% {
    transform: translateX(-50%) translateY(0);
  }
}
@keyframes animateCo54 {
  0% {
    transform: rotate(-4deg);
  }
  12.5% {
    transform: rotate(-20.92deg);
  }
  50% {
    transform: rotate(-20.92deg);
  }
  62.5% {
    transform: rotate(-4deg);
  }
  100% {
    transform: rotate(-4deg);
  }
}
</style>
